$primary-color: #0067ac;
$secondary-color: #de466d;
$text-danger: #cc4d4d;
$semi-primary: #4eabe1;
$text-info: #646e76;
$light-info: #8b9daa;
$text-light: #ffffff;
$semi-light: #fafafa;
$semi-info: #e9edf2;
$dark: #000;
$text-gray:#646E76;
$text-light-gray: #8B9DAA;

// font-size
$font-8: 8px;
$font-9: 9px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-15: 15px;
$font-16: 16px;


//toaster color-------
$warning-bg-color : #d4b350;
$success-bg-color:#28a745;
$error-bg-color: #dc3545;
