.nav-menus {
  li {
    #Ellipse_739,
    #Line_181,
    #Rectangle_816,
    #Path_942,
    #Rectangle_817,
    #Path_941,
    #Stroke-1,
    #Stroke-3 {
      stroke: var(--primary-icon-color);
    }

    #Path_941,
    #Path_942,
    #Path_876,
    #Path_877,
    #Path_878 {
      fill: var(--primary-icon-color);
    }

    &:hover {
      #Path_941,
    //  #Path_942,
      #Path_876,
      #Path_877,
      #Path_878 {
        fill: var(--primary-hover-active-color);
      }
      #Path_942{
        fill:var(--primary-bg-color);
      }
      // #Rectangle_816{
      //   fill:var(--primary-icon-color);
      // }
      #Ellipse_739,
      #Line_181,
       #Rectangle_816,
      #Path_942,
      #Rectangle_817,
      #Path_941,
      #Stroke-1,
      #Stroke-3 {
        stroke: var(--primary-hover-active-color);
      }
    }
  }

  li.active {
    #Path_941,
    #Path_942,
    #Path_876,
    #Path_877,
    #Path_878 {
      fill: var(--primary-hover-active-color);
    }

    #Ellipse_739,
    #Line_181,
    #Rectangle_816,
    #Path_942,
    #Rectangle_817,
    #Path_941,
    #Stroke-1,
    #Stroke-3 {
      stroke: var(--primary-hover-active-color);
    }
  }
}

li {
  #Path_734,
  #Path_735,
  #Path_736,
  #Path_737,
  #Path_738,
  #Path_739,
  #Path_740,
  #Path_741,
  #Path_742,
  #Path_743,
  #Path_744,
  #Path_745,
  #Path_746,
  #Path_747,
  #Path_748,
  #Path_749,
  #Path_750,
  #phone {
    fill: var(--secondary-text-icon-color);
  }
  #Stroke-1,
  #Stroke-2,
  #Stroke-4,
  #Stroke-6 {
    stroke: var(--secondary-text-icon-color);
  }
  a:hover {
    #Path_734,
    #Path_735,
    #Path_736,
    #Path_737,
    #Path_738,
    #Path_739,
    #Path_740,
    #Path_741,
    #Path_742,
    #Path_743,
    #Path_744,
    #Path_745,
    #Path_746,
    #Path_747,
    #Path_748,
    #Path_749,
    #Path_750,
    #path_0198,
    #requirement,
    #salesOrder,
    #PickShip,
    #invoice,
    #Layer_1,
    #vendor,
    #inventory,
    #phone,
    #Path_959,
    #Ellipse_21,
    #Path_955,
    #Path_956,
    #Path_957,
    #Path_958,
    #list {
      fill: var(--secondary-hover-active);
    }
    #Stroke-1,
    #Stroke-2,
    #Stroke-4,
    #Stroke-6 {
      stroke: var(--secondary-hover-active);
    }
  }
  a.active {
    #Path_734,
    #Path_735,
    #Path_736,
    #Path_737,
    #Path_738,
    #Path_739,
    #Path_740,
    #Path_741,
    #Path_742,
    #Path_743,
    #Path_744,
    #Path_745,
    #Path_746,
    #Path_747,
    #Path_748,
    #Path_749,
    #Path_750,
    #path_0198,
    #requirement,
    #salesOrder,
    #PickShip,
    #invoice,
    #Layer_1,
    #vendor,
    #inventory,
    #phone,
    #Path_959,
    #Ellipse_21,
    #Path_955,
    #Path_956,
    #Path_957,
    #Path_958,
    #list {
      fill: var(--secondary-hover-active);
    }
    #Stroke-1,
    #Stroke-2,
    #Stroke-4,
    #Stroke-6 {
      stroke: var(--secondary-hover-active);
    }
  }
  a{
    #Layer_1,#requirement{
      fill:var(--secondary-text-icon-color);
    }
  }
}

li{
  #Path_1025,
  #Path_1026,
  #Path_1027,
  #Path_1028,
  #Path_1029,
  #Path_1030,
  #Path_1031,
  #Path_1032,
  #Path_1033,
  #Path_1036,
  #Path_1037,
  #Path_1038,
  #Path_1039,
  #Rectangle_884,
  #Path_1040,
  #Path_1041,
  #Path_1042,
  #Path_1043,
  #Path_1044
  {
    fill: var(--secondary-text-icon-color);
  }
  #path1296,
  #path1300,
  #path1304,
  #path1308,
  #path1312,
  #path1314,
  #path1318,
  #path1322,
  #path1326,
  #path1330,
  #path1334,
  #path1338,
  #path1342
  {
    stroke: var(--secondary-text-icon-color);
  }
  #Path_1045,
  #Path_1046,
  #Path_1047,
  #Path_1048,
  #Path_1049,
  #Path_1050,
  #Path_1051
  {
    fill: var(--secondary-text-icon-color);
    stroke: var(--secondary-text-icon-color);
  }
  a{
    &:hover
    {
      #Path_1025,
      #Path_1026,
      #Path_1027,
      #Path_1028,
      #Path_1029,
      #Path_1030,
      #Path_1031,
      #Path_1032,
      #Path_1033,
      #Path_1036,
      #Path_1037,
      #Path_1038,
      #Path_1039,
      #Rectangle_884,
      #Path_1040,
      #Path_1041,
      #Path_1042,
      #Path_1043,
      #Path_1044
      {
        fill: var(--secondary-hover-active);
      }
      #path1296,
      #path1300,
      #path1304,
      #path1308,
      #path1312,
      #path1314,
      #path1318,
      #path1322,
      #path1326,
      #path1330,
      #path1334,
      #path1338,
      #path1342
      {
        stroke: var(--secondary-hover-active);
      }
      #Path_1045,
      #Path_1046,
      #Path_1047,
      #Path_1048,
      #Path_1049,
      #Path_1050,
      #Path_1051
      {
        fill:    var(--secondary-hover-active);
        stroke:  var(--secondary-hover-active);
      }
    }
  }
  a.active
  {
    #Path_1025,
    #Path_1026,
    #Path_1027,
    #Path_1028,
    #Path_1029,
    #Path_1030,
    #Path_1031,
    #Path_1032,
    #Path_1033,
    #Path_1036,
    #Path_1037,
    #Path_1038,
    #Path_1039,
    #Rectangle_884,
    #Path_1040,
    #Path_1041,
    #Path_1042,
    #Path_1043,
    #Path_1044
      {
        fill: var(--secondary-hover-active);
      }
    #path1296,
    #path1300,
    #path1304,
    #path1308,
    #path1312,
    #path1314,
    #path1318,
    #path1322,
    #path1326,
    #path1330,
    #path1334,
    #path1338,
    #path1342
      {
      stroke: var(--secondary-hover-active);
      }
      #Path_1045,
      #Path_1046,
      #Path_1047,
      #Path_1048,
      #Path_1049,
      #Path_1050,
      #Path_1051
      {
        fill:    var(--secondary-hover-active);
        stroke:  var(--secondary-hover-active);
      }
  }
}