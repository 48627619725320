/* ---------------------
 *** Vendors Scss ***
-----------------------*/



@import "vendors/chartist";
@import "vendors/scrollable";
@import "vendors/sticky";
@import "vendors/todo";


/* Toastr */
@import "~ngx-toastr/toastr.css";

// Ngx-Tabset
@import "ngx-tabset/ngx-tabset";

/* Dragula */
@import "~dragula/dist/dragula.css";

/* Emoji */
@import "@ctrl/ngx-emoji-mart/picker";

/* ---------------------
	*** Theme Scss ***
-----------------------*/
@import "animate.css/animate.min.css";
@import "bootstrap/scss/bootstrap";
@import "style";
@import "responsive";



