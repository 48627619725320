/* You can add global styles to this file, and also import other style files */
@import '~flatpickr/dist/flatpickr.min.css';
@import "./assets/scss/app.scss";
/* You can add global styles to this file, and also import other style files */
@import "../src/assets/variable.scss";

// ::-webkit-scrollbar {
//   width: 5px;
//   // width: 6px;
//   height: 6px;
// }

@import "./assets/scss/themIconColor/iconColor.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
$font-Poppins: "Poppins";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
.modal-content {
  border-radius: 20px;
}
.modal-title {
  color: var(--sitewide_primary_accent_color);
}

// :root[theme=dark]
// {
//   --theme-background: #222226;
//   --theme-color: rgba(255,255,255,.75);
// }
html {
  font-family: "Poppins", sans-serif;
  // background: var(--primary-bg-color);
  // color: var(--theme-color);
  // transition: all ease-in-out 400ms;
}
//   :root {
//     --theme-background: white;
//     --theme-color: black;
//   }
.page-body {
  background-color: var(--primary-colors);
}
//     input[type="color"]{
// width: 300px;
//     }

.hand {
  cursor: pointer !important;
}

.hands {
  cursor: pointer !important;
}



.f-10 {
  font-size: 10px !important;
}
.f-11 {
  font-size: 11px !important;
}
// new font class
.font-8 {
  font-size: $font-8;
}
.font-9 {
  font-size: $font-9;
}
.font-10 {
  font-size: $font-10;
}
.font-11 {
  font-size: $font-11;
}
.font-12 {
  font-size: $font-12;
}

.font-13 {
  font-size: $font-13;
}
.font-15 {
  font-size: $font-15;
}
.font-16 {
  font-size: $font-16;
}
// new font class

.fw-bold {
  font-weight: bold;
}
$secondary: var(--secondary-color);

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

// layout css
.page-wrapper.compact-wrapper {
  .page-header {
    margin-left: 0;
    width: 100%;
    border-bottom: 4px solid var(--secondary-accent-line-color);
    .header-wrapper {
      padding: 0;
    }
    &.close_icon {
      margin-left: 0;
      width: 100%;
    }
  }
  .page-body-wrapper .page-body {
    background-color: #ffffff;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .footer {
    width: 100%;
    margin-left: 0;
    z-index: 3;
  }
}
// layout css

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

// common style for project
* {
  font-family: "Poppins";
  letter-spacing: 0;
}
// common style for project

// color classes

.text-primary-color {
  color: var(--sitewide_primary_accent_color);
}
.text-semi-primary-color {
  color: $semi-primary;
}
.text-light-info {
  color: $light-info !important;
}
.text-gray {
  color: $text-gray !important;
  // font-weight: bold;
}
.text-light {
  color: $text-light;
}
.text-semi-light {
  color: $semi-light;
}
.text-light-gray {
  color: $text-light-gray !important;
}
.text-semiPrimary-color {
  color: $semi-primary;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-info-color {
  color: $text-info !important;
  span{
      color: var(--sitewide_primary_accent_color);
      cursor: pointer;
  }
}
.text-danger-color {
  color: $text-danger;
}
// color classes

// background-color classes
.bg-semi-light {
  background-color: $semi-light;
}
.bg-primary-color {
  background-color: var(--sitewide_primary_accent_color);
}

.bg-light-color {
  background-color: $text-light;
}
// background-color classes

//font-size
.font-15 {
  font-size: $font-15;
}
.font-16 {
  font-size: $font-16;
}
.font-12 {
  font-size: $font-12;
}
.font-13 {
  font-size: $font-13;
}
.font-11 {
  font-size: $font-11;
}
.font-10 {
  font-size: $font-10;
}
//

//width
.w-5em {
  // width: 5em;
}
.actInactive {
  width: 1.7cm;
}

// start remove focus for checkbox and switch
.form-check-input:focus {
  outline: none;
  box-shadow: none;
}
// end remove focus

// add Cursor pointer for checkbox and switch
[role="checkbox"],
[role="switch"],
svg,
select {
  cursor: pointer;
}
// add pointer for checkbox and switch

// Addcompany popup

.addCompanyPopUP {
  .modal-dialog {
    // scale: 86%;
    max-width: 1500px;
    font-size: $font-12;
  }
}

.addContactPopup {
  .modal-dialog {
    // scale: 86%;
    max-width: 1250px;
    font-size: $font-12;
  }
}

.filterCompanyPopUP {
  .modal-dialog {
    max-width: 1000px;
    font-size: $font-12;
  }
}
.container-h {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.radio label {
  padding-left: 3px;
}
.theme-form {
  .checkbox label {
    padding-left: 13px;
  }
}
.ngx-slider {
  margin: 0px !important;
  cursor: pointer;

  .ngx-slider-pointer {
    width: 14px !important;
    height: 14px !important;
    top: -5px !important;
    background-color: #fff !important;
    border: 1px solid var(--sitewide_primary_accent_color);
  }
  .ngx-slider-bar {
    left: 3px !important;
    width: 99% !important;
    height: 4px !important;
    background: $text-light-gray !important;
  }
  .ngx-slider-selection {
    background: var(--sitewide_primary_accent_color) !important;
    border-radius: 3px;
  }
  .ngx-slider-bubble {
    display: none !important;
  }
  .ngx-slider-pointer:after {
    display: none !important;
  }
  .ngx-slider-bar-wrapper {
    height: auto !important;
  }
}

// ::-webkit-scrollbar {
//   width: 3px;
//   height: 8px;
//   background: rgb(244, 244, 244);
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #848688;
//   height: 10px !important;
// }
.required-feild {
  color: red !important;
}

app-add-company {
  tabset {
    .nav-tabs {
      // pointer-events: var(--peNone);
      .nav-item {
        margin-right: 1.1rem;

        .nav-link {
          padding-bottom: 0.45rem;
          padding-left: 0px;
          font-size: $font-12;
          color: $text-light-gray;
          background-color: transparent;
          border: none;
          &:focus-visible {
            outline: none; 
            border-color: rgba(194, 219, 254, 255);
            box-shadow: 0 0 5px rgba(194, 219, 254, 255);
            box-shadow: 0 0 0 0.15rem rgba(194, 219, 254, 255);
          }
          &:hover {
            border-color: transparent;
            border: none;
            box-shadow: none;
          }

          &.active {
            border-bottom: 1px solid var(--sitewide-active-hover-color);
            color: var(--sitewide-active-hover-color);
            font-weight: normal;
            border-top: none;
            border-left: none;
            border-right: none;
          }
        }
      }
    }
  }

  ul {
    li {
      &:first-child {
        .nav-link {
        padding-right: 10px;
        }
        &::after {
          color: $text-danger;
          top: 23px;
          left: 9.6rem;
          content: "*";
          position: absolute;
          transform: translate(-100%, 0);
        }
      }

      &:nth-child(2n) {
        .nav-link {
          padding-right: 13px;
          }
        &::after {
          color: $text-danger;
          top: 23px;
           left: 19.2rem;
      //    left: 18.8rem;
          content: "*";
          position: absolute;
          transform: translate(-100%, 0);
        }
      }
      &:nth-child(3n),  &:nth-child(4n) {
        .nav-link {
          padding-right: 0px;
          }
        }
    }
  }
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
.checkLable {
  margin-left: 0.8rem !important;
  position: relative;
  top: 2px;
}
@media only screen and (min-width: 1100px) and (max-width: 1440px) {
  .addCompanyPopUP {
    .modal-dialog {
      max-width: 1292px !important;
      font-size: 12px;
      scale: 85%;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .addCompanyPopUP {
    .modal-dialog {
      scale: 82%;
    }
  }

  .addEditUserPopup {
    .modal-dialog {
      scale: 80%;
    }
  }

  .shippingProvAndMethodsPopup {
    .modal-dialog {
      scale: 80%;
      max-width: fit-content;
    }
  }

  .warehousePopup {
    .modal-dialog {
      scale: 80%;
      max-width: fit-content;
    }
  }

  .addContactPopup {
    .modal-dialog {
      scale: 80%;
      max-width: fit-content;
    }
  }
}

.pe-none {
  pointer-events: none;
}
// end add company popup

.border-red {
  .form-floating {
    .form-control,
    .form-control:focus,
    .form-control:not(:placeholder-shown),
    .form-select {
      border: 1px solid red !important;
    }
  }
  .wrap_Multiselect-div {
    border: 1px solid red !important;
  }
}

//floatin label css for input and dropdown manthan
.form-floating {
  .form-control,
  .form-control:focus,
  .form-control:not(:placeholder-shown),
  .form-select {
    padding: 1rem 2.7rem 0rem 2rem;
    color: $primary-color;
    height: calc(2.7rem + 2px);
    line-height: -1;
    border-radius: 10px !important;
    border: 1px solid $semi-info;
    box-shadow: none !important;
  }
  .form-control:not(:placeholder-shown) label,
  .form-select label,
  .form-control:focus label,
  .form-select:focus label {
    opacity: 1 !important;
  }
  span {
    padding: 1.2em 0.8em;
    .svgIcon {
      svg {
        stroke: $primary-color;
      }
    }
  }
  label {
    padding: 0.7rem 2.25rem;
    opacity: 1 !important;
    color: $text-light-gray;
    font-size: $font-13;
    transition: all 400ms ease-in-out !important;
    overflow: visible;
  }
  i {
    color: black;
    padding: 0.71rem;
  }
}

//floatin label css for input and dropdown manthan
.form-floating {
  .form-control,
  .form-control:focus,
  .form-control:not(:placeholder-shown),
  .form-select {
    padding: 1rem 2.4rem 0rem 2rem;
    font-size: $font-12;
   // color: var(--sitewide_primary_accent_color);
   color:#8b9daa;
    height: calc(2.7rem + 2px);
    line-height: -1;
    border-radius: 10px !important;
    border: 1px solid $semi-info;
    box-shadow: none !important;
    
  }
  .form-control:not(:placeholder-shown) label,
  .form-select label,
  .form-control:focus label,
  .form-select:focus label {
    opacity: 1 !important;
  }
  span {
    padding: 1.4em 0.8em;
    .svgIcon {
      svg {
        stroke: $primary-color;
      }
    }
  }
  label {
    padding: 0.7rem 2.25rem;
    opacity: 1 !important;
    color: $text-light-gray;
    font-size: $font-10;
    transition: all 400ms ease-in-out !important;
  }
  i {
    color: black;
    padding: 1rem;
  }
}
//end

//common search input
// search input :start
$color_inputSearch: $secondary-color;
.input_searchContainer {
  border-bottom: 1px solid $text-light-gray;
  .searchIcon {
    padding: 0.3rem;
    svg #Path_861{
      fill: var(--sitewide_primary_accent_color) !important;
      stroke: var(--sitewide_primary_accent_color) !important;
    }
  }
  .search_input {
    border: none;
    color: var(--sitewide_primary_accent_color);
    outline: none;
    width: -webkit-fill-available;
    font-size: $font-13;
  }
  .search_input::placeholder {
    color: var(--sitewide_primary_accent_color);
  }
  .clearSearchInput {
    // display: none;
    bottom: 6px;
    right: 1%;
    cursor: pointer !important;
    svg {
      fill: var(--sitewide_primary_accent_color);
      stroke: var(--sitewide_primary_accent_color);
    }
  }
}
//// :end

// columnSettingBlock css

.columnSettingBlock {
  .column-search {
    app-svg-icon {
      svg {
        fill: #8b9daa;
        stroke: #8b9daa;
      }
    }
  }
}
#diskette {
  fill: var(--sitewide_primary_accent_color);
}

//columnSettingBlock css

//   for report and permission page svg icon
app-roles-and-permissions {
  height: 100%;
  app-svg-icon {
    #editIcon,
    #deleteIcon,
    #down_arrow,
    #up_arrow {
      width: 15px;
      position: relative;
      height: 15px;
      top: 4px;
    }

    #down_arrow,
    #up_arrow {
      width: 18px;
      height: 18px;
    }
  }
}
//   modalPopUps width set
.addEditUserPopup {
  .modal-dialog {
    max-width: 1160px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1440px) {
  .codeCrosswalk {
    .modal-dialog {
      scale: 80%;
    }
  }
  
  
  .addEditUserPopup {
    .modal-dialog {
     // scale: 70%;
      scale: 80%;
    }
  }

  //role List active and selected Scss
  .ative-role-div {
    column-gap: 1em;
    row-gap: 0.7em;
  }
}

//addEdit user

//role List active and selected Scss
.ative-role-div {
  column-gap: 1em;
  row-gap: 0.7em;
}

// image cropper
image-cropper {
  display: flex;
  position: relative;
  width: 175px !important;
  max-width: 100%;
  max-height: 100%;
  left: 0px;
  overflow: hidden;
  padding: 0px;
  top: -8px;
  height: 177px;
  border-radius: 5px;
  // border: 3px solid #000000;

  .overlay {
    width: 165px;
    height: 167px;
    margin-left: calc(50% - 82.5px);
    position: absolute;
    pointer-events: none;
    touch-action: none;
    outline: var(--cropper-overlay-color, #fff) solid 100vw;
    top: 0;
    left: 0;
  }

  .ngx-ic-cropper {
    width: 100%;
    height: 100%;
    margin-left: calc(50% - 76px);
    visibility: visible;
    display: flex;
    border: 1px solid #000000;
    border-radius: 100%;
    outline: #000000 solid 100vw !important;
    touch-action: none;

    .ngx-ic-move {
      width: 100%;
      cursor: move;
      border: 1px solid #2b2b2b;
    }
    .ngx-ic-resize {
      .ngx-ic-square {
        background: none !important;
        border: none !important;
        box-sizing: content-box;
      }
    }
  }

  img {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  }
}

//image cropper end

.selected {
  button {
    background-color: var(--sitewide_primary_accent_color) !important;
    color: $text-light !important;
  }
}
.btn-selected {
  background-color: var(--sitewide_primary_accent_color) !important;
  color: $text-light !important;
}
//end
//addEditUserEnd
//common popup width
.markAsActiveInactive {
  .modal-dialog {
    max-width: 516px;
  }
}
.codeCrosswalk {
  .modal-dialog {
    max-width: 1500px;
    .modal-content {
      border: none;
    }
  }
}

.markAsAddEditAddress {
  .modal-dialog {
    // max-width: 690px;
    max-width: 750px;
  }
}
//end

//common popup width css

//   modalPopUps width set
.addEditUserPopup {
  .modal-dialog {
    max-width: 1160px;
  }
}
//addEdit user

.markAsActiveInactive {
  .modal-dialog {
    max-width: 516px;
  }
}

.resetPassword {
  .modal-dialog {
    max-width: 417px;
  }
}
.credit_terms {
  .modal-dialog {
    max-width: 350px;
  }
}

.passwordChanged {
  .modal-dialog {
    max-width: 356px;
  }
}

.transfer{
  .modal-dialog {
    max-width: 380px;
  }
}

// @media only screen and (min-width: 1100px) and (max-width: 1440px) {
//   .addEditUserPopup {
//     .modal-dialog {
//       scale: 70%;
//     }
//   }

//   .markAsActiveInactive {
//     .modal-dialog {
//       scale: 70%;
//     }
//   }

//   .markAsAddEditAddress {
//     .modal-dialog {
//       scale: 70%;
//     }
//   }
//   .resetPassword {
//     .modal-dialog {
//       scale: 70%;
//     }
//   }
//   .credit_terms {
//     .modal-dialog {
//       scale: 70%;
//     }
//   }

//   .passwordChanged {
//     .modal-dialog {
//       scale: 70%;
//     }
//   }
// }
//common popup width end.

// .text-primary-color {
//   color: var(--sitewide_primary_accent_color) !important;
// }

//addEditUserEnd

.stars {
  display: flex;
  ngx-star-rating {
    form {
      label.star,
      label.star::before {
        float: right;
        font-size: 13px !important;
        padding: 0 !important;
        color: #e9edf2;
        transition: all 0.2s;
        cursor: pointer;
      }
      input.star:checked ~ label.star:before {
        color: var(--sitewide_secondary_color) !important;
        transition: all 0.25s;
        text-shadow: none !important;
        cursor: pointer;
      }
    }
  }
}

//ag-grid css

.ag-header.ag-focus-managed.ag-pivot-off {
  padding-left: 2px;
}

.doc-grid {
  .ag-theme-alpine {
    overflow-x: auto;
    .ag-root-wrapper {
      border: none !important;
      .ag-header {
        border-top: 1px solid #e9edf2 !important;
        border-bottom: 2px solid var(--sitewide_primary_accent_color) !important;
        background-color: white !important;
        .ag-header-cell {
          font-size: 12px;
          color: var(--sitewide_primary_accent_color);
          .ag-header-select-all {
            margin-right: 0;
            padding-right: 18px;
          }
        }
      }
    }
    .ag-row {
      border: 1px solid transparent;
      border-bottom: 1px solid #e9edf2;
      // border-bottom:1px solid #FFFFFF !important;
      .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
        border-color: transparent !important;
      }
      .ag-cell {
        font-size: $font-13;
        border-right-width: 2px !important;
        .ag-selection-checkbox {
          margin-right: 0;
          padding-right: 18px;
        }
        &:focus {
          border: 1px solid transparent !important;
          box-sizing: border-box;
        }
        .ag-cell-value {
          padding-left: 10px;
          color: var(--sitewide_primary_accent_color);
        }
      }
      .ag-cell-inline-editing {
        height: auto;
        background-color: transparent;
        box-shadow: none !important;
        border-color: #ffffff !important;
      }
      .ag-cell.padding-left-38px {
        padding: 0 0 0 43px;
        color: $text-gray;
      }
    }
    // .ag-row:nth-child(odd) {
    //   background-color: #fafafa;
    // }
    // .ag-row:nth-child(even) {
    //   background-color: #ffffff;
    // }
     .ag-row-even {
      background-color:#ffffff; /* Add your desired color for even rows */
    }
    
    /* Apply styles to odd rows */
     .ag-row-odd {
      background-color: #fafafa; /* Add your desired color for odd rows */
    }
  }
}

.code-crosswalk{
  .ag-theme-alpine{
    .ag-root-wrapper {
      .ag-header {
        border-bottom: 1px solid var(--sitewide_primary_accent_color) !important;
        background-color: transparent !important;
        .ag-header-cell-comp-wrapper{
          img{
            display: none !important;
          }
        }
      }
      .ag-row{
        border-bottom: 1px solid #E9EDF2;
        background-color: transparent !important;
       
        .ag-cell.padding-left-20px {
          padding: 0 0 0 20px;
          color: $text-gray;
        }

        .ag-cell-value {
          color: #646E76;
        }

        .ag-cell-wrapper{
          .ag-cell.padding-left-0{
              padding: 0 !important;
          }
        }
        
      }

    }


  }
}

.ag-theme-alpine {
  .ag-root-wrapper {
    border: none !important;
    .ag-pinned-left-cols-container {
      position: initial;
    }
    .ag-center-cols-container {
      // left: -1px;
    }
    .ag-center-cols-clipper {
      .ag-center-cols-viewport {
        height: calc(100% + 7px)!important;
        .ag-center-cols-container {
          left: -1px;
        }
      }
    }
    .ag-header {
      border-bottom: none !important;
      background-color: #e9edf2 !important;
      .ag-header-cell {
        font-size: 12px;
        color: var(--sitewide_primary_accent_color);
        .ag-header-select-all {
          margin-right: 0;
          padding-right: 18px;
        }
        .ag-checkbox-input-wrapper:not(:checked)::after {
          border-color: var(--sitewide_primary_accent_color);
        }
        .ag-checkbox-input-wrapper.ag-checked::after {
          border-color: white;
        }
      }
    }
  }
//   .ag-header-cell .ag-header-cell-resize {
//     right: 10px !important;
// }
  .ag-center-cols-viewport {
    // overflow-x: hidden;
  }
  .ag-body-horizontal-scroll {
    height: 6px !important;
    max-height: 6px !important;
    min-height: 6px !important;
    .ag-body-horizontal-scroll-viewport {
      height: 8px !important;
      max-height: 6px !important;
      min-height: 6px !important;
    }
  }

  .ag-checkbox-input {
    border-radius: 1px;
    cursor: pointer !important;
  }
  .ag-checkbox-input-wrapper {
    font-family: var(--ag-icon-font-family);
    font-size: var(--ag-icon-size);
    line-height: var(--ag-icon-size);
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 18px;
    border: none;
    height: 18px;
    background-color: red !important;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    flex: none;
    position: relative;
    top: -1px;
    cursor: pointer;
  }
  .ag-header-select-all {
    .ag-checkbox-input-wrapper {
      position: relative;
      top: 1px;
      box-shadow: none;
    }
  }
  .ag-checkbox-input-wrapper::after {
    color: transparent;
    position: absolute;
    top: -1px;
    left: 0;
    pointer-events: none;
    width: 19.2px;
    height: 19.2px;
    border-radius: 5px;
    left: 0px;
    border: 1.3px solid $text-light-gray;
    background-color: transparent;
  }
  .ag-checkbox-input-wrapper {
    background-color: var(--sitewide_accent_color) !important;
    box-shadow: none;
    &.ag-checked::after {
      position: absolute;
      content: "";
      border-radius: 5px;
      transform: rotate(45deg) scale(1);
      left: 7px;
      top: 3px;
      width: 4px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      border-radius: 0;
      background: transparent;
    }
  }
  .ag-checkbox-input-wrapper.ag-checked {
    background-color: var(--sitewide_primary_accent_color) !important;
  }

  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    position: absolute;
    content: "";
  }

  .ag-row {
    // border: 1px solid transparent !important;
    // border-bottom:1px solid #FFFFFF !important;
    .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
      border-color: transparent !important;
    }
    .ag-cell {
      font-size: $font-13;
      border-right-width: 2px !important;
      .ag-selection-checkbox {
        margin-right: 0;
        padding-right: 18px;
      }
      &:focus {
        border: 1px solid transparent !important;
        box-sizing: border-box;
      }
      .ag-cell-value {
        //padding-left: 18px;
         padding-left: 2px;
        color: var(--sitewide_primary_accent_color);
      }
    }
    .ag-cell-inline-editing {
      height: auto;
      background-color: transparent;
      box-shadow: none !important;
      border-color: #ffffff !important;
    }
    .ag-cell.padding-left-38px {
    //  padding: 0 0 0 43px;
      //padding: 0 0 0 48px;
       padding: 0 0 0 20px;
      color: $text-gray;
    }
    .ag-cell.padding-left-45px {
      padding: 0 0 0 45px;
    }
    .ag-cell.padding-left-0px{
      padding: 0 0 0 0px;
    }

  }
  .ag-row-selected {
    border: 1px solid var(--sitewide_primary_accent_color) !important;
    background-color: #ffffff !important;
    .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
      border-color: transparent !important;
    }
    .ag-cell {
      background-color: transparent !important;
      &:focus {
        border: 1px solid transparent !important;
        box-sizing: border-box;
      }
      span {
        position: relative;
      }
    }
  }
  // .ag-row:nth-child(odd) {
  //   background-color: #fafafa;
  //   --ag-selected-row-background-color: #fafafa !important;
  // }
  // .ag-row:nth-child(even) {
  //   background-color: #ffffff;
  //   --ag-selected-row-background-color: #ffffff !important;
  // }
   .ag-row-even {
    background-color: #ffffff;
    --ag-selected-row-background-color:#ffffff !important; 
  }
   .ag-row-odd {
    background-color: #fafafa;
    --ag-selected-row-background-color: #fafafa !important; 
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell
    ) {
    border: none;
  }
  .ag-pinned-left-header {
    border: none;
  }

  .ag-horizontal-left-spacer {
    border: none !important;
  }
}
.ag-row-hover:not(.ag-full-width-row)::before,
.ag-row-hover.ag-full-width-row.ag-row-group::before {
  background-color: #fff !important;
}
// Pagination

.pagination-control {
  .pagination {
    // justify-content: center !important;
    .page-item {
      .page-link {
        padding: 2px 8px !important;
        color: $text-light-gray !important;
        border: 1px solid $semi-info !important;
        border-color: $semi-info !important;
        font-size: $font-11;
      }
    }

    .page-item.active {
      .page-link {
        background-color: #fff !important;
        border-color: $semi-info !important;
        color: var(--sitewide_primary_accent_color) !important;
        font-size: $font-11;
      }
    }
  }
}

.border-0 {
  border: 1px solid transparent !important;
}
// .ag-center-cols-container .custom-row-style .ag-cell:nth-child(5),
// .custom-row-style .ag-cell:nth-child(6)
// {
//   background-color: yellow !important;
//  height: 32px !important;
// }
.ag-center-cols-container .custom-row-style .ag-cell:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-last-child(2)):not(:nth-last-child(1)) {
  height: 32px !important;
}

.row-border {
  // border-left: 1px solid var(--sitewide_primary_accent_color) !important;
  border: 1px solid var(--sitewide_primary_accent_color) !important ;
  // border-bottom: 1px solid var(--sitewide_primary_accent_color) !important;
}
.expandable-grid {
  .ag-cell{
    --ag-line-height: 30px !important;
  }
}

// .ag-pinned-left-cols-container{
//   .row-border {
//     border: 1px solid var(--sitewide_primary_accent_color) !important;
//   }
// }
// .ag-center-cols-clipper{
//   .row-border {
//     border: 1px solid var(--sitewide_primary_accent_color) !important;
//     border-left:0px solid !important;
//   }
// }
// .ag-cell
// {
//   height: initial;
// }
// .ag-row {
//   position: initial;
// }
.ag-row-inline-editing {
  z-index: auto;
}
.position-initial {
  position: initial !important;
}
.left-px {
  left: 0 !important;
}
.width-0 {
  width: 0px !important;
  padding: 0;
}

// Pagination

// for column settings
.ag-theme-alpine.sideColumnSetting {
  .ag-header {
    min-height: 0 !important;
    height: 0 !important;
  }
  .ag-row {
    background-color: #ffffff !important;
    .ag-cell {
      .ag-cell-value {
        padding-left: 2px;
      }
    }
  }
  .ag-row-drag {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .ag-body-horizontal-scroll {
    height: 0px !important;
    max-height: 0px !important;
    min-height: 0px !important;
    .ag-body-horizontal-scroll-viewport {
      height: 0px !important;
      max-height: 0px !important;
      min-height: 0px !important;
    }
  }
}
// for column settings

//ag-grid css
app-footer {
  app-svg-icon {
    #XMLID_1_,
    #LookUpFooterIcon,
    #Path_751,
    #phone,
    #customer_1_,
    #Iconly_Light_Work,
    #Work,
    #Stroke-1,
    #Path_959,
    #Ellipse_21,
    #contact,
    #file {
      width: 23px !important;
      height: 27px !important;
      stroke: var(--dock-icon-color);
      fill: var(--dock-icon-color);
    }
  }
  app-svg-icon {
    #Group-8,
    #Stroke-2,
    #Stroke-4,
    #Stroke-6 {
      width: 23px !important;
      height: 27px !important;
      stroke: var(--dock-icon-color);
    }
  }
}

.footer {
  background-color: var(--dock-bg-color);
}
.add-req app-svg-icon {
  #Path_817{
    //stroke: var(--sitewide_primary_accent_color);
    stroke: var(--sitewide_secondary_color);
  }
}
app-svg-icon {
  #Path_943,
  #Path_944,
  #Path_945,
  #Path_817,
  #Path_942{
    stroke: var(--sitewide_secondary_color);
  }
  #Ellipse_739,
  #Line_181,
  #Path_1484,
  #Path_1485 {
    stroke: var(--sitewide_primary_accent_color);
    
  } 
  #Path_1512,#Path_1513 {
    stroke: var(--sitewide_secondary_color);
    fill: var(--sitewide_secondary_color);
  }
    #add_5_ {
      width: 10px;
      height: 10px;
      #Path_1485,  #Path_1484{
        stroke: var(--sitewide_secondary_color);
        fill: var(--sitewide_secondary_color);
      }
    }
    #Edit_icon {
      // width: 10px;
      // height: 10px;
      #Path_987,  #Path_988{
        stroke: var(--sitewide_secondary_color);
        fill: var(--sitewide_secondary_color);
      }
    }

}

// --for Ncm Code popup by vrushabh--
.height-135 {
  app-input {
    .form__input {
      height: 135px !important;
    }
  }
}
// --for Ncm Code popup--
app-my-account {
  .profilewidth {
    width: 174px;
  }
  .onhover-show-div {
    left: -8.4rem;
    top: 38px;
    height: 83px !important;
  }
  li {
    padding-left: 10px;
    margin-top: 12px;

    a {
      span {
        padding-left: 10px;
        font-size: 12px;
      }
    }
  }
  app-feather-icons {
    padding-left: 10px;
    svg {
      height: 18px;
      width: 18px;
      vertical-align: middle;
    }
  }
}

app-svg-icon {
  #Union_1 {
    stroke: var(--sitewide_primary_accent_color);
    fill: var(--sitewide_primary_accent_color);
  }
}
.position-left-5px {
  position: relative;
  left: 5px;
  width: 28px;
}

///siderbar Notification start

.sideBarContainer {
  .sideBar {
    .tab-container {
      .nav-tabs {
        justify-content: center;
        @media only screen and (max-width: 1100px) {
          justify-content: space-around;
        }
        gap: 3.9em;
        border-bottom: 2px solid white !important;
        .nav-item {
          border: none !important;
          .nav-link.active :first-child {
            font-weight: bold !important;
          }
          .nav-link {
            padding: 0 !important;
            background-color: transparent !important;
            border: none !important;
            span {
              font-size: 1.1em;
              padding-right: 0.6em;
              color: $text-gray !important;
            }
          }
        }
      }
    }
  }
}
///siderbar Notification end

.role-pin-actve {
  position: relative;
  top: 1px;
  width: 28px;
  height: 28px;
  left: 0.1rem;
}

.not-allowed {
  cursor: not-allowed;
}

// clear filter icon
.clear-color {
  color: var(--sitewide_secondary_color);
}
.text-semi-primary {
  color: var(--sitewide_secondary_color);
}

// import company
.download-svg-icon {
  stroke: var(--sitewide_secondary_color);
}
.svgicon {
  stroke: $text-light-gray;
}

//Jayshri
.pinnedColor {
  svg {
    #Un {
      fill: var(--sitewide_primary_accent_color) !important;
    }
  }
}

//userRole edit icon dynamic color start //(manthan)
.editIcon_span {
  .editIconColor {
    svg {
      stroke: var(--sitewide_primary_accent_color);
      fill: var(--sitewide_primary_accent_color);
    }
  }
}

//color-picker start
.color_input {
  color-picker {
    position: relative !important;
    top: -4px !important;
    right: -19.1rem !important;

    .color-picker {
      top: 0 !important;
      left: 0 !important;
      position: absolute !important;
      .arrow {
        opacity: 0;
      }
    }
  }
}
//color-picker end
//userRole edit icon dynamic color end//
.cursor-notAllowed {
  cursor: not-allowed !important;
}
// header
#drop_arrow :hover {
  stroke: var(--secondary-hover-active) !important;
}

#Path_861 {
  fill: blue !important;
  outline: red !important;
}
// for company-page apply filter start
.filterBar {
  .primary-bg {
    .btn {
      background-color: var(--primary-bg-color) !important;
      border: none !important;
      .m-l-5 {
        color: white !important;
      }
    }
  }
}
// for company-page apply filter end

//common radio button css start
.radio-p {
  input[type="radio"]:checked + label {
    color: var(--sitewide_primary_accent_color);
    &::before {
      border-color: var(--sitewide_primary_accent_color);
    }
  }
}

.radio {
  label {
    font-size: $font-12;
    color: $text-light-gray;
    &::before {
      border: 1px solid $text-light-gray;
      width: 14px;
      height: 14px;
      margin-top: 2px;
    }

    &::after {
      background-color: var(--sitewide_primary_accent_color);
      width: 8px;
      height: 8px;
      left: 3px;
      top: 5px;
    }
  }
}
// common radio button css end

.m-t-2 {
  margin-top: 2px;
}

.show-multiselect-dropdown {
  .optionDropdown {
    flex-flow: wrap;
    align-content: flex-start;
    display: flex;
  }
}

.dropdown-toggle::after {
  position: absolute !important;
  right: 10px !important;
}

// Region start lookup-table edit-icon
.edit-svg {
  app-svg-icon  #Path_987 {
    // svg {
      stroke: var(--sitewide_primary_accent_color);
      fill: var(--sitewide_primary_accent_color);
    // }
  }
  app-svg-icon  #Path_988 {
      stroke: var(--sitewide_primary_accent_color);
      fill: var(--sitewide_primary_accent_color);
  }

}
// Region start lookup-table edit-icon
//tooltip changes css
.filterBar {
  ngb-tooltip-window.bs-tooltip-bottom {
    .arrow {
      position: absolute;
      left: 1.1rem !important;
      top: 0.5px;
    }

    .arrow:before {
      position: absolute;
      content: url("./assets/SVGIcons/trangle.svg");
      top: -7px;
      transform: rotate(269deg);
      z-index: -1;
    }

    .arrow:after {
      position: absolute;
      content: url("./assets/SVGIcons/innerTriangle.svg");
      top: -0.5px;
      left: -4.7px;
      z-index: 1;
    }

    .tooltip-inner {
      color: #000;
      text-align: center;
      background-color: #fff;
      border: 1px solid #646e76;
      transform: translate(5px, 1px);
      padding: 3px 18px;
    }
  }
}

.footer-copyright {
  .arrow {
    position: absolute;
    left: 1.1rem !important;
    top: 40px;
  }

  .arrow:before {
    position: absolute;
    content: url("./assets/SVGIcons/trangle.svg");
    top: -9px;
    transform: rotate(93deg);
    z-index: -1;
    left: -5px;
  }

  .arrow:after {
    position: absolute;
    content: url("./assets/SVGIcons/innerTriangle.svg");
    top: -14.5px;
    left: -4px;
    z-index: 1;
    transform: rotate(183deg);
  }

  .tooltip-inner {
    color: #000;
    text-align: center;
    background-color: #fff;
    border: 1px solid $text-gray;
    transform: translate(5px, 1px);
    // padding: 3px 17px;
  }
}

// start textarea css for contact or details tabs
.addNote {
  // border: 1px solid #b0b2b5a1;
  border: 1px solid #e9edf2;
  border-radius: 10px;
  height: 106px;
  .addLabel {
    left: 10%;
    top: 5%;
    color: $text-light-gray;
  }
  textarea:focus {
    box-shadow: none !important;
  }
  .Textarea {
    border: none;
    outline: none;
    color: var(--sitewide_primary_accent_color);
    height: 100%;
    width: 94% !important;
    resize: none;
  }
  .form-control {
    padding: 0.37rem 1.2rem !important;
  }
}
// end textarea css for contact or details tabs

//start for header sub-menu Icons
.sub-menu-item {
  li {
    a:hover {
      span {
        color: var(--secondary-hover-active) !important;
      }
    }
  }
}
//end for header sub-menu Icons

app-button {
  .btn-hover:hover {
    color: white;
    background-color: var(--sitewide-active-hover-color);
  }
}

.checkBox-gap {
  .form-check {
    padding-left: 0;
  }
  .form-check-input {
    float: left !important;
    margin-left: -0.5em !important;
  }
}

app-fulfillment {
  app-checkbox {
    .form-check {
      .form-check-input {
        height: 15px !important;
        width: 15px !important;
        border-color: #8b9daa;
        position: relative;
        left: 0.4rem;
      }
    }
  }
}

.cursor-default {
  cursor: default !important;
}
.borders {
  border: 1px solid red !important;
}
.contact-info {
  height: calc(100vh - 122px) !important;
  box-sizing: border-box !important;
  // overflow: hidden !important;
  // overflow: hidden;
  transform-origin: 0 0;
  transform: scaleX(1) scaleY(1);
  position: fixed;
}

.grid-thumb {
  // ::-webkit-scrollbar {
  //   width: 8px;
  // }
  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #646e76;
  }
}
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar:horizontal {
  height: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #646e76;
}
.hand {
  cursor: pointer !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.star-full,
.star-half {
  background-color: var(--sitewide-active-hover-color) !important;
  border-color: blue !important;
  -webkit-text-stroke-color: #d0dded !important;
  -webkit-text-stroke-width: 0px !important;
}
.star-empty {
  background-color: lightgray !important;
}
.ng-star-inserted:hover ~ .star-full:hover {
  background-color: #e6e036 !important;
  border-color: blue !important;
}

app-add-edit-sale-comminssion {
  .sale-rate {
    .form-floating {
      .form-control {
        padding-top: 1.4rem !important;
        padding-bottom: 1rem !important;
        padding-left: 1.5rem !important;
        padding-right: 1rem !important;
      }
    }
  }
  .red {
    .form-floating {
      .form-control {
        border: 1px solid red;
      }
    }
  }
}

app-add-edit-purchase-commission {
  .sale-rate {
    .form-floating {
      .form-control {
        padding-top: 1.4rem !important;
        padding-bottom: 1rem !important;
        padding-left: 1.5rem !important;
        padding-right: 1rem !important;
      }
    }
  }
  .red {
    .form-floating {
      .form-control {
        border: 1px solid red;
      }
    }
  }
}
app-user-management {
  ngb-tooltip-window.bs-tooltip-bottom {
    .arrow {
      position: absolute;
      left: 1.1rem !important;
      top: 0.5px;
      
    }

    .arrow:before {
      position: absolute;
      content: url("./assets/SVGIcons/trangle.svg");
      top: -7px;
      right: -5px;
      transform: rotate(269deg);
      z-index: -1;
    }

    .arrow:after {
      position: absolute;
      content: url("./assets/SVGIcons/innerTriangle.svg");
      top: -0.5px;
      left: -18.7px;
      z-index: 1;
    }

    .tooltip-inner {
      padding: 0.25rem 0.5rem;
      color: #8b9daa;
      text-align: center;
      border: 1px solid #8b9daa;
      background-color: #fff;
      border-radius: 6px;
      font-size: 12px;
      width: 155px;
      position: absolute;
      left: -102px;
    }
  }
}
app-add-company {
  app-general-information {
    .disableradiobtn {
      .radio label::before {
        background-color: #e9edf2 !important;
        cursor: not-allowed !important;
      }
    }
    .notallow 
    {
      cursor: not-allowed !important;
    }
    .radio input[type="radio"]:disabled + label {
      opacity: 1 !important;
      cursor: not-allowed !important;
    }
  }
}
.fw 
{
  background: #e9edf2 !important;
  border-radius: 9px !important;
  select{
    cursor: not-allowed !important;
  }
.form-check-input:disabled 
{
  opacity: 1;
  background-color: #e9edf2;
  cursor: not-allowed !important;
}
.form-check-input:disabled ~ .form-check-label 
{
  opacity: 1;
}}
.span-ellipsis {
  display: inline-block;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
}

.manage-manufacturer {
  .modal-dialog {
    max-width: 600px;
  }
}

.customize-serchInput {
  border: 1px solid $semi-info;
  border-radius: 7px;
  padding: 10px;

  app-search-input {
    .input_searchContainer {
      border: none;

      .searchIcon svg,
      .clearSearchInput svg {
        // fill: var(--sitewide_primary_accent_color);
        // stroke: var(--sitewide_primary_accent_color);
      }

      .search_input,
      .search_input::placeholder {
        color: $light-info;
      }
    }
  }
}

.alphabet-data {
  app-button {
    .button-lable-primary {
      background: transparent;
      color: $text-light-gray;
      border-radius: 5px;
    }
  }
}

.mnufacturer-list {
  app-checkbox {
    label {
      max-width: 155px;
    }
    .check-label {
      color: var(--sitewide_primary_accent_color) !important;
    }
  }
}
// app-date-range-piker {
//   .range-datepicker {
//     ngb-datepicker {
//       border-radius: 8px;
//       top: 5px !important;
//       border-color: #e9edf2;
//       color: #1b1e20;
//       .ngb-dp-header {
//         padding-top: 10px;
//         .ngb-dp-arrow {
//           .btn-link {
//             color: #646e76;
//             &:hover {
//               color: #646e76;
//             }
//           }
//         }
//       }
//       .ngb-dp-navigation-select {
//         gap: 48px;
//         .custom-select {
//           border: none;
//           font-size: 16px;
//           &:focus {
//             outline: none;
//           }
//         }
//       }
//       .ngb-dp-months {
//         .ngb-dp-month {
//           .ngb-dp-month-name {
//             font-size: 12px;
//           }
//           .ngb-dp-week {
//             font-size: 12px;
//             .ngb-dp-day {
//               width: 2rem!important;
//               height: 2rem!important;
//               &:focus {
//                 outline: none;
//               }
//               .custom-day {
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 border-radius: 4px;
//                 padding: 0;
//                 height: 1.5rem;
//                 width: 2rem;
//                 font-size: 12px;
//                 color: #646e76;
//                 line-height: 1.5rem;
//               }
//               .custom-day.range {
//                 background-color: var(--sitewide_primary_accent_color);
//                 color: white;
//                 // border: 1px dashed rgba(blue , 0.7);
//                 border-radius: 0;

//                 &.start-date {
//                   border-left: none;
//                   border-top-left-radius: 4px;
//                   border-bottom-left-radius: 4px;
//                 }
//                 &.end-date {
//                   border-right: none;
//                   border-top-right-radius: 4px;
//                   border-bottom-right-radius: 4px;
//                 }
//               }
//               .custom-day:hover {
//                 background-color: var(--sitewide_primary_accent_color);
//                 color: white;
//               }

//               .custom-day.focused {
//                 background-color: var(--sitewide_primary_accent_color);
//                 color: white;
//                 height: 1.5rem;
//                 width: 1.5rem;
//               }
//               .custom-day.faded {
//                 border-radius: 0;
//                 background-color: #e9edf2;
//                 border: none;
//                 border-left: none;
//                 color: #646e76;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
// .datepickerSelect{
//   border-radius: 1em!important;
//   border-color: blue!important;
//   padding: 1.5em;
//   }

//   .ngb-dp-day{
//     height: 2.7rem!important;
//     width: 2.7rem!important;
//   }

.slected-div {
  border: 1px solid var(--sitewide_primary_accent_color) !important;
}

.activity:last-child {
  .rowdata:last-child {
    .call-border:last-child {
      display: none;
    }
  }
}
.change-bgcolor{
  background-color: $semi-light;
  border: none !important;
}

app-tasks,app-contacts-tab{
  app-svg-icon{
    #Edit_icon{
width: 10px !important;
height: 10px !important;
    }
  }
}
.wrapper-2
{
  // width: 130px !important;
  width: 100%!important;
  border: 1px solid #e9edf2 !important;
  border-radius: 8px !important;
  height: 30px !important;
}
app-activity-manager{
  .ngb-dp-day {
    // margin-top: 0.5em !important;
    width: 100% !important;
    // height: 4.6em !important;
    height: 3.4em !important;
    margin-top: 0.1em !important;
  }
  .activity  ngb-datepicker-month{
    margin-bottom: 10px;
    max-height: 290px;
  }
 .orderDetail ngb-datepicker{
  right: 0px !important;
  left: auto !important;
 }
  @media screen and (min-width: 1708px) and (max-width: 2047px) {
    .activity ngb-datepicker-month {
      height: calc(100vh - 630px);
      overflow: scroll;
      
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1707px) {
    .activity ngb-datepicker-month {
      height: calc(100vh - 580px);
      overflow: scroll;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .activity ngb-datepicker-month {
      height: calc(100vh - 530px);
      overflow: scroll;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .activity ngb-datepicker-month {
      height: calc(100vh - 480px);
      overflow: scroll;
    }

  }


  @media screen and (min-width: 320px) and (max-width: 375px){
    .activity ngb-datepicker-month {
      height: calc(100vh - 430px);
      overflow: scroll;
    }
    
  }

  @media screen and (min-width: 568px) and (max-width: 667px) {
    /* Adjust styles for iPhone SE landscape mode */
    .activity ngb-datepicker-month {
      height: calc(100vh - 320px);
      overflow: scroll;
    }
  }

     
  @media screen and (min-width: 1025px) and (max-width: 1707px) {
    // .activity ngb-datepicker-navigation {
    //   display: none !important;
    //   }
    // .activity ngb-datepicker-next {
    //   display: none !important;
    //   }
    // .activity ngb-datepicker-next::before {
    //   content: ">";
    //   font-size: 20px;
    //   color: #8B9DAA;
    //   }
    }
  
  .ngb-dp-week:last-child {
    display: none !important;
  }


}

//  Add Certificate Date picker 

app-add-certifications{
  @media screen and (min-width: 1708px) and (max-width: 2047px) {
    .addCertification ngb-datepicker-month  ngb-dp-header{
      height: calc(100vh - 630px);
      overflow: scroll;
      margin-left: 10px;
      
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1707px) {
    .addCertification ngb-datepicker-month ngb-dp-header {
      height: calc(100vh - 580px);
      overflow: scroll;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .addCertification ngb-datepicker-month ngb-dp-header {
      height: calc(100vh - 530px);
      overflow: scroll;
    }
  }

}

.orderDetail app-date-picker {
  .form-floating {
    .form-control{
      height: calc(2rem + 2px);
      color: #8B9DAA;
      padding: 0.8rem .7rem 0rem 1.8rem !important;
    }
    label{
      padding: 0.6rem 0.8rem;
    }
    img{
    right: 9% !important;
    top: 0.7em !important;
    }
  }
 }


.form-floating {
  .ngb-dp-day {
    margin-top: 0.5em !important;
    width: 2.5em !important;
    height: 2em !important;
  }
  .btn-light{
    background-color:none!important;
  }
  .ngb-dp-weekday {
    width: 2.8em !important;
  }
  .ngb-dp-arrow {
    span {
      padding: 0 !important;
    }
  }
}

mention-list{
  ul{
    cursor: pointer;
    li{
      .mention-active:active{
        background-color: grey !important;
      }
      border-bottom: 1px solid $semi-info !important;

      a{
        padding-left: 5px !important;
      }
    }
    border-radius: 10px !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute !important;
    top: 15px !important;
  }
   .scrollable-menu{
    max-height: 153px !important;
  }
}

.select-All-activity{
  label{
    color:  var(--sitewide_secondary_color) !important;
  }
}
.invalide-border
{
  .date-container
  {
    .form-control
    {
      border:  1px solid red !important;
    }
}
}


ngb-datepicker 
{
// width: 100%;
  border: 1px solid var(--sitewide_primary_accent_color) !important;
  border-radius:1em !important;
  margin-top: -15px !important;
  .ngb-dp-header {
   // padding-top: 1rem !important;
    padding-top: 0.6rem !important;
  }
  .ngb-dp-content {
    width: 100%;
  }
  .ngb-dp-month{
    width: 100%;
  }
   .ngb-dp-arrow-btn {
    background-color: var(--sitewide_primary_accent_color)!important;
    border-radius: 8px 0px 0px 8px;
    margin: 0 1rem !important;
    position: relative;
    // left: 280%;
    left: 300%;
    width: 21px;
    height: 24px;
  }
  .ngb-dp-navigation-chevron {
    border-width:0 !important;
    -webkit-transform: rotate(-1deg) !important;
    transform: rotate(-1deg) !important;
    position: relative;
    background-image: url('./assets/SVGIcons/Group 1022.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
  }
  
   ngb-datepicker-navigation-select{
    position: relative !important;
    left:-18%;
  }
    @media (min-width: 768px) and (max-width: 1024px){
   ngb-datepicker-navigation-select {
      left: -13%;
  } 
    }

  ngb-datepicker-navigation-select>.custom-select {
    pointer-events: none !important;
    flex: none !important;
    padding: 0 1px !important; 
    border: none !important;
    appearance: none !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color:  var(--sitewide_primary_accent_color)!important;
  }
  
   .right .ngb-dp-arrow-btn {
    background-color: var(--sitewide_primary_accent_color)!important;
    border-radius: 0px 8px 8px 0px;
    position: unset;
  }
  .right .ngb-dp-navigation-chevron {
    -webkit-transform: rotate(180deg) !important;
    transform: rotate(180deg) !important;
    margin-left: 0.15em;
    margin-right: 0.25em;
  }
  
   .ngb-dp-navigation-chevron {
    width: 0.55em;
    height: .55em;
    border-width:0 !important;
    -webkit-transform: rotate(-1deg) !important;
    transform: rotate(-1deg) !important;
    position: relative;
    background-image: url('./assets/SVGIcons/Group 1022.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .ngb-dp-weekday {
    color:  var(--sitewide_primary_accent_color) !important;
    font-weight: bolder !important;
    font-style: normal !important;
    width:100% !important;
  }
.ngb-dp-month 
{
  &:first-child .ngb-dp-week
  {
    row-gap: 0.5em !important;
    text-align: center;
    color: lightblue;
    // @media only screen and (max-width: 800px) 
    // {
    //   column-gap: 0.5rem !important;
    // }
  }
  &:last-child .ngb-dp-week 
  {
    color: #646e76 ;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  @media (min-width: 768px) and (max-width: 1024px){
    &:last-child .ngb-dp-week 
    {
      color: #646e76 ;
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
  
    }

}
.ngb-dp-weekdays {
  border-bottom: 1px solid transparent !important;
}
  
}

//ngb dropdown customize icon
.dropdown-toggle::after{
 display: none !important;
}
.requirement{
  span.ng-star-inserted {
    color: var(--sitewide_primary_accent_color);
    }
    .dropdown-menu.show {
      display: block;
      width: 250px;
    }
    .ngb-highlight{
      padding: 0 !important;
    }
    .dropdown-menu .dropdown-item {
      font-size: 10px;
      line-height: 1.4;
      color: var(--sitewide_primary_accent_color);
   

  }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--sitewide_primary_accent_color);
    background-color:#e9edf2;
}  
}
@media (min-width: 768px) and (max-width: 1024px){
  ngb-datepicker-navigation{
    margin-right: 30px;
    margin-left: -6%; 
   }
  }
  .datepicker-label{
    label{
      font-size: 14px;
      // text-align: left;
      float: right;
      padding: 0.7rem;
      // height: auto;
    }
  }
  .taskdate label{
    font-size: 14px;
    // text-align: left;
    float: right;
    padding: 0.7rem;
    // height: auto;
  }

  .taskdate img{
    height: auto;
    max-width : 20px
  }


  .taskdate ngb-datepicker {
    right: auto !important;
    left: auto !important;
    font-size:  12px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .ngb-dp-header{
      margin-left: 20px;
    }
    .ngb-dp-day{ 
      height: 1.9rem !important;
      width: 1.9rem !important;  
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
  
  }
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        &:focus-visible {
          outline: none; 
          border-color: rgba(194, 219, 254, 255);
          box-shadow: 0 0 5px rgba(194, 219, 254, 255);
          box-shadow: 0 0 0 0.15rem rgba(194, 219, 254, 255);
        }
      }
    }
  }


  // Activity Manager dropdown 

  .activity-dropdwon{
    app-dropdown .form-floating label{
      color: #646E76 !important;
    }
  }
.text-red-color{
  color: #CE0000;
}
//  add user  
.user-info-container .ext{
    app-input{
      .form-floating label{
        justify-content: center;
      }
    }
}
.requirement-info {
  // height: calc(100vh - 157px) !important;
   box-sizing: border-box !important;
   transform-origin: 0 0;
   transform: scaleX(1) scaleY(1);
   position: fixed;
   .active-button{
     background-color: var(--sitewide_primary_accent_color) ;
     color: white;
     border-radius: 5px;
     border:none;
     padding-top:.7em;
     padding-bottom: .7em;
     padding-left: 2em;
     padding-right:2em;
   }
   .not-active{
     border: none;
     color: #646E76;
     padding-top:.7em;
     padding-bottom: .7em;
     padding-left: 2em;
     padding-right:2em;
     background-color: transparent;
   }
   
 }
.requirements{
  .ag-theme-alpine {
    .ag-root-wrapper .ag-center-cols-clipper .ag-center-cols-viewport .ag-center-cols-container {
      // width: 100% !important;
     }
    .ag-row .ag-cell .ag-selection-checkbox {
      padding-right: 0px !important;
  }
  .ag-root-wrapper .ag-center-cols-clipper .ag-center-cols-viewport .ag-center-cols-container {
    left: 0px !important;
}
  }
} 
.partHeight{
  height: 297px !important;
  // height: 16.2rem !important;
}
.requirementHeight{
  height: 297px !important;
  margin-bottom: 5px !important;
}
.bg-sky-color{
  background-color: #59B2D3;
}
.bg-grey-color{
  background-color:#646E76;
}
.right-0{
  right: 0;
 }
 .ag-theme-alpine .ag-row .ag-cell.requirement-padding-left-18px .ag-cell-value {
  padding-left: 18px !important;
  color: var(--sitewide_primary_accent_color) !important;
}

/* Specific styles for Part Number column */
.doc-grid .ag-theme-alpine .custom-part-number-cell .ag-cell-value {
  padding-left: 2px !important; /* Specific padding */
  // color: var(--sitewide_primary_accent_color) !important; /* Accent color */
}
.doc-grid .ag-theme-alpine .ag-row .ag-cell.custom-part-number-cell {
  
  color: #646E76;
}
.hide-odd-color{
 .doc-grid .ag-theme-alpine.ag-row-odd {
  background-color: #ffffff !important;
 }
.ag-theme-alpine .ag-row-odd {
  background-color: #ffffff !important;
}
}
