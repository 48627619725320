// all colora
$primary-color: #0067AC;
$secondory-color: #DE466D;
$danger-color: #EA5A5A;
$semi-primary: #4EABE1;
$info:#646E76;
$light-info:#8B9DAA;
$light:#FFFFFF;
$semi-light:#FAFAFA;
$semi-info:#E9EDF2;

//for cont size
$font-10 :10px;
$font-11:11px;
$font-12: 12px;
$font-13:13px;
$fotn-14: 14px;
$font-15: 15px;

// height $ width
$height-30: 30px;
$height-28:28px;
$height-29:29px;
$width-87:87px;
$border-radius-8:8px;

.button
{
    width: auto;
    background: $light;
    height: $height-28;
    // margin-top: 2px;
    font-size: $font-12;
    white-space: nowrap;
    padding: 0px 8px;
    // margin-right: .6em;
    // margin-left: .6em;
    border-radius: $border-radius-8;
    cursor: pointer;
    &.button-info
    {
        border: 1px solid $light-info;
        color: $light-info;
    }

    &.button-primary
    {
        border: 1px solid var(--sitewide_secondary_color);
        color: var(--sitewide_secondary_color);
    }
    &.button-danger
    {
        border: 1px solid $danger-color;
        color :  $danger-color;
        
    }
    &.activeprimary
    {
        border: 1px solid var(--sitewide_secondary_color);
        color: var(--sitewide_secondary_color);
    }
    &.activedanger
    {
        border: 1px solid $danger-color;
        color :  $danger-color;
    }
    &.disable-btn{
        border: 1px solid $light-info;
        color: $light-info;
        cursor: not-allowed;
    }
    &.dark-info{
        border: 1px solid $info;
        color : $info;
    }

}

.button-lable-primary
    {
        display: inline-block;
        background: $light-info;
        margin-left: 6px;
        color:$light;
        // margin-bottom: 7px;
        height: 26px;
        width: auto;
        border: none;
        text-align: start;
        border-radius: 8px;
        white-space: nowrap;
        padding-left: 8px;
        padding-right: 8px;
        // padding-top: 0px;
        font: normal normal normal 11px Poppins;
        opacity: 1;
    }

.sm-button
{
     font-size: 10px;
    border: none;
    border-radius: 13px;
    color: $light;
    padding-left: 2.5em;
    padding-right: 2.5em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    white-space: nowrap;
    letter-spacing: 0.2px;
    margin-right: 20px;
}
.sms-button {
    font-size: 12px;
    border: none;
    border-radius: 8px;
    color: #FFFFFF;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    white-space: nowrap;
    letter-spacing: 0.2px;
    margin-right: 16px;
    margin-bottom: 6px;
}

.btn-sm
{
    width: auto;
    background: $light;
    height: 28px;
    // margin-top: 2px;
    font-size: 11px;
    white-space: nowrap;
    padding: 5px 11px;
    border-radius: 8px;
    &.button-info
    {
        border: 1px solid $light-info;
        color: $light-info;
    }

    &.button-primary
    {
        border: 1px solid var(--sitewide_secondary_color);
        color: var(--sitewide_secondary_color);
    }
    &.button-danger
    {
        border: 1px solid $danger-color;
        color :  $danger-color;
        
    }
    &.activeprimary
    {
        border: 1px solid $semi-primary;
        color: $semi-primary;
    }
    &.activedanger
    {
        border: 1px solid $danger-color;
        color :  $danger-color;
    }
    &.disable-btn{
        border: 1px solid $light-info;
        color: $light-info;
        cursor: not-allowed;
    }
}


.confirm-btn 
{
    border: 1px solid var(--sitewide_secondary_color);
    color: var(--sitewide_secondary_color);
    font-size: 11px;
    background-color: white;
    border-radius: 6px;
    margin-left: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  
  .cancel-btn 
  {
    border: 1px solid #8b9daa;
    border-radius: 6px;
    color: #8b9daa;
    font-size: 11px;
    background-color: white;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-right: 1em;
  }
  .delete-btn 
  {
    border: 1px solid #cc4d4d;
    border-radius: 6px;
    color: #cc4d4d;
    font-size: 11px;
    background-color: white;
    padding-left: 1em;
    padding-left: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-left: 1em;
  }
  